import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.replace.js";
import $ from "jquery";
import { $escape, randomId } from "../utils";
import { shinyUnbindAll } from "./initedMethods";
import { renderContent } from "./render"; // Milliseconds to fade in or out

var fadeDuration = 250;

function show() {
  var _$notificationInit;

  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$html = _ref.html,
      html = _ref$html === void 0 ? "" : _ref$html,
      _ref$action = _ref.action,
      action = _ref$action === void 0 ? "" : _ref$action,
      _ref$deps = _ref.deps,
      deps = _ref$deps === void 0 ? [] : _ref$deps,
      _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? 5000 : _ref$duration,
      _ref$id = _ref.id,
      id = _ref$id === void 0 ? null : _ref$id,
      _ref$closeButton = _ref.closeButton,
      closeButton = _ref$closeButton === void 0 ? true : _ref$closeButton,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? null : _ref$type;

  if (!id) id = randomId(); // Create panel if necessary

  createPanel(); // Get existing DOM element for this ID, or create if needed.

  var $notificationInit = get(id);
  if (((_$notificationInit = $notificationInit) === null || _$notificationInit === void 0 ? void 0 : _$notificationInit.length) === 0) $notificationInit = create(id);
  var $notification = $notificationInit; // Render html and dependencies

  var newHtml = "<div class=\"shiny-notification-content-text\">".concat(html, "</div>") + "<div class=\"shiny-notification-content-action\">".concat(action, "</div>");
  var $content = $notification.find(".shiny-notification-content");
  renderContent($content, {
    html: newHtml,
    deps: deps
  }); // Remove any existing classes of the form 'shiny-notification-xxxx'.
  // The xxxx would be strings like 'warning'.

  var classes = $notification === null || $notification === void 0 ? void 0 : $notification.attr("class");

  if (classes) {
    var classVal = classes.split(/\s+/).filter(function (cls) {
      return cls.match(/^shiny-notification-/);
    }).join(" ");
    $notification.removeClass(classVal);
  } // Add class. 'default' means no additional CSS class.


  if (type && type !== "default") $notification.addClass("shiny-notification-" + type); // Make sure that the presence/absence of close button matches with value
  // of `closeButton`.

  var $close = $notification.find(".shiny-notification-close");

  if (closeButton && $close.length === 0) {
    $notification.append('<div class="shiny-notification-close">&times;</div>');
  } else if (!closeButton && $close.length !== 0) {
    $close.remove();
  } // If duration was provided, schedule removal. If not, clear existing
  // removal callback (this happens if a message was first added with
  // a duration, and then updated with no duration).


  if (duration) addRemovalCallback(id, duration);else clearRemovalCallback(id);
  return id;
}

function remove(id) {
  var _get;

  (_get = get(id)) === null || _get === void 0 ? void 0 : _get.fadeOut(fadeDuration, function () {
    shinyUnbindAll(this);
    $(this).remove(); // If no more notifications, remove the panel from the DOM.

    if (ids().length === 0) {
      getPanel().remove();
    }
  });
} // Returns an individual notification DOM object (wrapped in jQuery).


function get(id) {
  if (!id) return null;
  return getPanel().find("#shiny-notification-" + $escape(id));
} // Return array of all notification IDs


function ids() {
  return getPanel().find(".shiny-notification").map(function () {
    return this.id.replace(/shiny-notification-/, "");
  }).get();
} // Returns the notification panel DOM object (wrapped in jQuery).


function getPanel() {
  return $("#shiny-notification-panel");
} // Create notifications panel and return the jQuery object. If the DOM
// element already exists, just return it.


function createPanel() {
  var $panel = getPanel();
  if ($panel.length > 0) return $panel;
  $(document.body).append('<div id="shiny-notification-panel">');
  return $panel;
} // Create a notification DOM element and return the jQuery object. If the
// DOM element already exists for the ID, just return it without creating.


function create(id) {
  var _$notification;

  var $notification = get(id);

  if (((_$notification = $notification) === null || _$notification === void 0 ? void 0 : _$notification.length) === 0) {
    $notification = $("<div id=\"shiny-notification-".concat(id, "\" class=\"shiny-notification\">") + '<div class="shiny-notification-close">&times;</div>' + '<div class="shiny-notification-content"></div>' + "</div>");
    $notification.find(".shiny-notification-close").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      remove(id);
    });
    getPanel().append($notification);
  }

  return $notification;
} // Add a callback to remove a notification after a delay in ms.


function addRemovalCallback(id, delay) {
  var _get2;

  // If there's an existing removalCallback, clear it before adding the new
  // one.
  clearRemovalCallback(id); // Attach new removal callback

  var removalCallback = setTimeout(function () {
    remove(id);
  }, delay);
  (_get2 = get(id)) === null || _get2 === void 0 ? void 0 : _get2.data("removalCallback", removalCallback);
} // Clear a removal callback from a notification, if present.


function clearRemovalCallback(id) {
  var $notification = get(id);
  var oldRemovalCallback = $notification === null || $notification === void 0 ? void 0 : $notification.data("removalCallback");

  if (oldRemovalCallback) {
    clearTimeout(oldRemovalCallback);
  }
}

export { show as showNotification, remove as removeNotification };